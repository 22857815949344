<template>
    <div class="Projectcation Notreview" style="margin-top:20px;">
        <!-- <div class="Project_button">
            <el-button type="primary" size="mini" @click="dialogVisible=true">添加批次</el-button>
        </div> -->
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" label="#" width="55px"> </el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="isYouth" label="青年红色筑梦之旅项目">
                <template slot-scope="scope">
                    <span>{{scope.row.isYouth==0?'是':'否'}}</span>
                </template>
            </el-table-column>   
            <el-table-column prop="states" label="状态" show-overflow-tooltip>
                <template>
                    <span>待评审</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="170px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleClick(scope.row)" size="mini">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalnum">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            totalnum:0,
            dialogVisible:false,
            currentPage:1,
            tableData:[],
        }
    },
    methods: {
        getapp(){
            this.axios.reviewwlist({
                params:{
                    uId:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        handleClick(rows){
            this.$router.push({name:'Reviewdetails',query:{itemId:rows.itmId,val:1}})
        },
        //关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        handleClose(done){
            console.log(done)
        },
        handleSizeChange(val){
            console.log(`当前页：${val}`)
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.tableData = this.newarrays[val - 1]
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-form-item__content{
    width: 350px;
}
.tcation_Popup .el-dialog__body{
    padding: 30px 50px;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation.Notreview .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation.Notreview .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>